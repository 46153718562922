import React from "react"

import { withArticleDetails } from "./withArticleDetails"
import { ArticleCard } from "./ArticleCard"
import { getFluidGatsbyImage, routeResolver } from "../Wrappers/Helpers"
import { RichText } from "../RichText/RichText"
import { Title } from "../Title/Title"
import { Grid } from "../Grid/Grid"
import { Container, Responsive, Heading, Image, DateTime, Outset, GridColumn } from "./ArticleDetails.styled"

export const ArticleDetails = withArticleDetails(
  ({ article: { attributes, content, image, title }, articles, template: { relatedAll, relatedTitle, showRelated } }: Props): JSX.Element => (
    <>
      <Responsive screen={`<md`}>
        <Title backLink={"/articles"}>
          <Heading>{title}</Heading>
        </Title>
      </Responsive>

      {image && (
        <Container spacing={`sm`} width={`xl`}>
          <Outset>
            <Image alt={image.alt} image={getFluidGatsbyImage(image, { maxWidth: 2000 })} />
          </Outset>
        </Container>
      )}

      <Container spacing={`md`} width={`md`}>
        {attributes?.publishedAt && (
          <DateTime>
            {new Intl.DateTimeFormat("en-AU", {
              day: "2-digit",
              month: "long",
              year: "numeric",
              timeZone: "Australia/Melbourne",
            }).format(Date.parse(`${new Date(attributes.publishedAt)}`))}
          </DateTime>
        )}
        <Responsive screen={`md>`}>
          <Heading>{title}</Heading>
        </Responsive>

        <RichText>{content}</RichText>
      </Container>

      {showRelated && articles?.length > 0 && (
        <Container collapse={`<md`} spacing={`md`} topSpacing={`md`} width={`xl`}>
          <Grid show={3} title={relatedTitle} viewMoreLink={routeResolver({ type: `articles` })} viewMoreButton={relatedAll} viewMoreArrow>
            {articles?.map(({ date, id, image, location, summary, title, attributes, ...item }) => (
              <GridColumn key={id}>
                <ArticleCard
                  size={`full`}
                  image={image}
                  title={title}
                  content={summary}
                  link={routeResolver({ item })}
                  date={`${(attributes?.publishedAt && new Date(attributes.publishedAt)) || ""}`}
                />
              </GridColumn>
            ))}
          </Grid>
        </Container>
      )}
    </>
  )
)

export interface Props {
  article: {
    content: string
    title: string
    image: any
    attributes: {
      publishedAt: Date
    }
  }
  articles?: Array<any>
  template?: any
}
