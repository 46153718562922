import { GatsbyImage } from "gatsby-plugin-image"
import tw, { styled } from "twin.macro"

import { H1 } from "../../styled/Text"

export { StyledContainer as Container } from "../../styled/Container"
export { StyledResponsive as Responsive } from "../../styled/Responsive"
export { StyledGridColumn as GridColumn } from "../../styled/Grid"

export const Heading = tw(H1)`mb-0 font-bold lg:text-3xl md:mb-12`
export const Image = styled(GatsbyImage)`
  ${tw`block pb-aspect-16/9 h-0 rounded-lg`}
  ${({ topMargin }) => topMargin && tw`-mt-36`}
`
export const DateTime = tw.time`block font-bold text-xxs leading-none uppercase tracking-wide text-green mb-4`
export const Outset = tw.div`-mx-4 md:mx-0 md:mt-12`
