import React from "react"
import { graphql } from "gatsby"

import { ArticleDetails as Template } from "../components/Article/ArticleDetails"

export const query = graphql`
  query($id: String!) {
    article: sanityArticle(_id: { eq: $id }) {
      ...GatsbyArticleFragment
    }
    # template: sanityTemplateArticle {
    #   additionalRelatedText
    #   additionalShareText
    #   settingShowRelated
    #   settingShowSocialShare
    # }
  }
`

export default ({ data, ...props }): JSX.Element => <Template {...props} {...data} />
