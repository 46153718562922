import React, { useEffect, useMemo } from "react"

import { useCore } from "../../hooks/useCore"
import { useSanityArticle } from "../../hooks/useSanity"
import { useKlaviyo } from "../../hooks/useKlaviyo"
import { useTemplate } from "../../hooks/useTemplate"

export const withArticleDetails = Component =>
  React.memo(({ name = "ArticleDetails", page, article, path, ...props }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { getRelatedArticles, related } = useSanityArticle()
    const { trackEvent } = useKlaviyo()
    const { article: template } = useTemplate()

    const title = page?.title
    const content = sanityContent(page?.content)

    trackEvent("Viewed Article", article)

    useEffect(() => {
      getRelatedArticles(article, 8, path)
    }, [article])

    Component.displayName = name
    return useMemo(
      () => <Component {...props} {...page} title={title} content={content} template={template} article={article} articles={related} />,
      [related]
    )
  })
